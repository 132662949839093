@tailwind base;
@tailwind components;
@tailwind utilities;

input {
  font-size: 14px;
}

button {
  line-height: 1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .fc-button-group .fc-timeGridDay-button,
  .fc-button-group .fc-timeGridWeek-button,
  .fc-button-group .fc-dayGridMonth-button,
  .fc-button-group .fc-listMonth-button {
    display: none;
  }
}

thead .back {
  position: absolute !important;
  bottom: 0;
  width: 100% !important;
  height: 55px !important;
  border-bottom: 10px solid #8969c0;
}

/* input class title */
.input-class-title > input {
  border: 1px solid black;
}
